import React, { useState, useEffect, useCallback, useContext } from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import App from '../../components/App'
import ProductListing from '../../components/ProductListing'
import { filteringOptions } from '../../components/ProductListing/filteringOptions.data'

import StoreContext from '../../contexts/StoreContext'
import { updateProduct } from '../../utils/helpers'

const PlantsPage = props => {
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')

  const { data } = props

  const collection = get(data, 'collections.edges[0].node')
  const products = get(data, 'collections.edges[0].node.products')

  const [collectionProducts, setCollectionProducts] = useState(products)

  const { client } = useContext(StoreContext)

  const checkAvailability = useCallback(
    collectionId => {
      client.collection
        .fetchWithProducts(collectionId, { productsFirst: 250 })
        .then(fetchedCollection => {
          for (let i = 0; i < collectionProducts.length; i++) {
            const product = collectionProducts[i]

            for (let j = 0; j < fetchedCollection.products.length; j++) {
              const shopifyProduct = fetchedCollection.products[j]
              if (product.shopifyId === shopifyProduct.id) {
                updateProduct(product, shopifyProduct)
                break
              }
            }
          }

          setCollectionProducts(collectionProducts)
        })
    },
    [collectionProducts]
  )

  useEffect(() => {
    checkAvailability(collection.id.replace('Shopify__Collection__', ''))
  }, [collection])

  return (
    <App className="products">
      <Helmet title={siteTitle}></Helmet>
      <ProductListing
        title="Plante"
        headerImage="/images/living_room_edit.jpg"
        products={collectionProducts}
        filteringOptions={filteringOptions}
      />
      <style jsx>{`
        .products {
        }
        .socialPosts {
          margin-left: -32px;
        }
        .mediaWrapper {
          margin-top: 32px;
          margin-left: 32px;
        }
      `}</style>
    </App>
  )
}

export const query = graphql`
  query PlantsListingQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    collections: allShopifyCollection(
      filter: { title: { eq: "Live plants" } }
    ) {
      edges {
        node {
          id
          descriptionHtml
          handle
          products {
            shopifyId
            id
            handle
            title
            description
            productType
            tags
            publishedAt
            availableForSale
            variants {
              shopifyId
              title
              price
              availableForSale
              selectedOptions {
                name
                value
              }
              image {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 960, maxHeight: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 960, maxHeight: 1280) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PlantsPage
